import React from 'react';

import ISO27001Icon from '../../assets/images/icons/iso-27001.png'
import CyberEssentialsPlusIcon from '../../assets/images/icons/cyber-essentials-plus.png'

function AccreditationSection() {

  return (
    <div className="container-fluid px-0 pt-100 pb-100 pt-sm-80 pb-sm-80 bg-white">
      <div className="accreditation-section fit-width">
        <h2 className="title text-center">Certifications</h2>
        <div className="items-container">
          <div className="item">
            <div className="image-container">
              <img src={ISO27001Icon} />
            </div>
            <div className="details">
              <div className="name">
                ISO27001
              </div>
              <div className="description">
                The most rigorous global security standard for Information Security Management Systems (ISMS)
              </div>
            </div>
          </div>
          <div className="item">
            <div className="image-container">
              <img src={CyberEssentialsPlusIcon} />
            </div>
            <div className="details">
              <div className="name">
                Cyber Essentials Plus
              </div>
              <div className="description">
                A scheme backed by the UK Government that helps to protect organisations against a range of common cyber attacks
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccreditationSection;
