import React, { useRef, useState, useEffect } from 'react'

import { Link } from 'gatsby'
import VimeoPlayer from '@vimeo/player'

import SwiperCore, { Controller, Navigation, Pagination } from 'swiper6'
import { Swiper, SwiperSlide } from 'swiper6/react';
import 'swiper6/swiper-bundle.min.css';
import 'swiper6/swiper.min.css';

import playWhite from "../../assets/images/icons/play-white.svg";
import play from "../../assets/images/icons/play.svg";
import masterclassLogo from "../../assets/images/logos/masterclass.png"
import byLaventuresLogo from "../../assets/images/logos/by-la-ventures.png"
import teaser1 from "../../assets/images/masterclass/teaser-1.jpg"
import teaser2 from "../../assets/images/masterclass/teaser-2.jpg"
import teaser5 from "../../assets/images/masterclass/teaser-5.png"
import lars from "../../assets/images/masterclass/lars-rasmussen.png"
import david from "../../assets/images/masterclass/david-kerr.png"
import jason from "../../assets/images/masterclass/jason-barnwell.png"
import stephaniechris from "../../assets/images/masterclass/stephanie-hamon-chris-grant.png"
import paul from "../../assets/images/masterclass/paul-hogg.png"
import catherine from "../../assets/images/masterclass/catherine-goodman.png"
import helga from "../../assets/images/masterclass/helga-butcher.png"
import stephanie from "../../assets/images/masterclass/stephanie-hamon.png"
import chris from "../../assets/images/masterclass/chris-grant-2.png"
import ralph from "../../assets/images/masterclass/ralph-baxter-2.png"
import mary from "../../assets/images/masterclass/mary-ocarroll.png"

import ArrowLeftIcon from '../../assets/svg/arrow-left.svg';
import ArrowRightIcon from '../../assets/svg/arrow-right.svg';


SwiperCore.use([Controller, Navigation, Pagination])

const Player = ( {currentVideo: {source, getStartedText, getStartedURL}} ) => {
  const [initialSource, setInitialSource] = useState(null)
  const player = useRef(null)
  const [playing, setPlaying] = useState(true)
  const playerController = useRef(null)

  useEffect(() => {
    if (!initialSource && source) {
      setInitialSource(source)
    }

    setPlaying(true)
    const player = document.getElementById('vimeo-iframe')
    if (player != null) {
      playerController.current = new VimeoPlayer(player)

      playerController.current.on('pause', () => {
        setPlaying(false)
      })
      playerController.current.on('play', () => {
        setPlaying(true)
      })
    }

    if (playerController.current != null && source != initialSource) {
      playerController.current.loadVideo(source).then(() => {
        playerController.current.play()
      })
    }
  }, [source, initialSource])

  return (
    <div className="teaser-player">
      <div
        style={{ padding: '42.19% 0 0 0', position: 'relative' }}
      >
        {initialSource && (
          <iframe
            id="vimeo-iframe"
            ref={player}
            src={initialSource}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title="Teaser 1 - Visionaries, Innovators, and Dreamers">
          </iframe>
        )}
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      {
        !playing && (
          <div className="overlay">
            <h2 className="get-started-label text-white">
              {getStartedText}
            </h2>
            <Link
              className="text-white border-white get-started btn"
              to={getStartedURL}
            >
              Get Started
            </Link>
          </div>
        )
      }
    </div>
  )
}

const Masterclass = () => {
  const [isPlaying, setIsPlaying] = useState(false)

  const [currentVideo, setCurrentVideo] = useState(null)

  return (
    <div className="container-fluid px-0 mx-0 bg-white">
      <div className="fit-width masterclass-section">
        <div className="header">
          <img className="logo" src={masterclassLogo} height="80px" />
        </div>
        {isPlaying ? (
          <Player
            currentVideo={currentVideo}
          />
        ) : (
          <div className="teaser">
            <div className="thumbnail">
              <img src={teaser1} />
              <button
                className="play-button center"
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                    getStartedText: "Watch Masterclass now",
                    getStartedURL: "/learn"
                  })
                }}
              >
                <img src={playWhite} className="icon" alt="play icon" />
              </button>
            </div>
            <div className="content">
              <div className="wrapper">
                <button
                  className="watch-button"
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                      getStartedText: "Watch Masterclass now",
                      getStartedURL: "/learn"
                    })
                  }}
                >
                  <img src={play} />
                  <div>Watch Trailer</div>
                </button>
                <h3>The Industry Titans</h3>
                <p>
                  Join our Visionaries, our Innovators, and our Dreamers as they share their insights on legal innovation.
                  <br />
                  <br />
                  #legaltech, it’s time!
                </p>
              </div>
            </div>
          </div>
        )}
        <div style={{ marginTop: 56, display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 15, textAlign: 'right', flex: 1 }}>
            <div style={{ fontWeight: 500 }}>Speakers</div>
          </div>
          <div style={{ marginLeft: 15, textAlign: 'left', flex: 1 }}>
            <Link className="btn" style={{ border: '1px solid #D2D6DB' }} to="/learn">Learn more</Link>
          </div>
        </div>
        <div className="speakers-section">
          <div
            style={{
              backgroundImage: `url(${lars})`,
              backgroundSize: 'cover',
              width: 232,
              height: 368,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              padding: '0 12px 24px 12px',
              textAlign: 'center',
              lineHeight: 1.2,
              cursor: 'pointer'
            }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                getStartedText: `Watch Lars Rasmussen's Masterclass now`,
                getStartedURL: '/learn/lawyers-and-technologists'
              })
            }}
          >
            <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>Lars Rasmussen</h5>
            <div style={{ color: 'white', fontSize: '10px' }}>
              Founder of Google Maps
              <br />
              Former Director of Engineering for Facebook
              <br />
              <br />
              Lawyers and Technologists
              <br />
              the twain shall meet
              <br />
              <br />
              11m 17s
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${david})`,
              backgroundSize: 'cover',
              width: 232,
              height: 368,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              padding: '0 12px 24px 12px',
              textAlign: 'center',
              lineHeight: 1.2,
              cursor: 'pointer'
            }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                getStartedText: `Watch David Kerr's Masterclass now`,
                getStartedURL: '/learn/judgment-day'
              })
            }}
          >
            <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>David Kerr</h5>
            <div style={{ color: 'white', fontSize: '10px' }}>
              Former CEO of Bird & Bird
              <br />
              <br />
              <br />
              Judgment Day
              <br />
              the rise of the tech-enabled law firm
              <br />
              <br />
              8m 14s
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${jason})`,
              backgroundSize: 'cover',
              width: 232,
              height: 368,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              padding: '0 12px 24px 12px',
              textAlign: 'center',
              lineHeight: 1.2,
              cursor: 'pointer'
            }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                getStartedText: `Watch Jason Barnwell's Masterclass now`,
                getStartedURL: '/learn/legal-innovation'
              })
            }}
          >
            <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>Jason Barnwell</h5>
            <div style={{ color: 'white', fontSize: '10px' }}>
              Microsoft’s Corporate Attorney
              <br />
              for External and Legal Affairs
              <br />
              <br />
              Legal Innovation
              <br />
              We can do more
              <br />
              <br />
              6m 30s
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Masterclass2 = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)

  const [activeSlide, setActiveSlide] = useState(0)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  return (
    <div className="container-fluid px-0 mx-0 bg-white">
      <div className="fit-width masterclass-section">
        <div className="header">
          <img className="logo" src={masterclassLogo} height="80px" />
        </div>
        {isPlaying && (
          <Player
            currentVideo={currentVideo}
          />
        )}
        <div style={{ display: isPlaying ? 'none' : 'block' }}>
          <Swiper
            loop
            allowTouchMove={false}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
            onSlideChange={(s) => {
              setActiveSlide(s.realIndex)
              setIsPlaying(false)
            }}
            pagination={{ clickable: true, el: '#masterclass-pagination' }}
            initialSlide={2}
          >
            <SwiperSlide>
              <div className="masterclass-teaser">
                <div className="thumbnail">
                  <img src={teaser1} />
                  <button
                    className="play-button center"
                    onClick={() => {
                      setIsPlaying(true)
                      setCurrentVideo({
                        source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                        getStartedText: "Watch Masterclass now",
                        getStartedURL: "/learn"
                      })
                    }}
                  >
                    <img src={playWhite} className="icon" alt="play icon" />
                  </button>
                </div>
                <div className="content">
                  <div className="wrapper">
                    <button
                      className="watch-button"
                      onClick={() => {
                        setIsPlaying(true)
                        setCurrentVideo({
                          source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                          getStartedText: "Watch Masterclass now",
                          getStartedURL: "/learn"
                        })
                      }}
                    >
                      <img src={play} />
                      <div>Watch Trailer</div>
                    </button>
                    <h3>The Industry Titans</h3>
                    <p>
                      Join our Visionaries, our Innovators, and our Dreamers as they share their insights on legal innovation.
                      <br />
                      <br />
                      #legaltech, it’s time!
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="masterclass-teaser">
                <div className="thumbnail">
                  <img src={teaser2} />
                  <button
                    className="play-button center"
                    onClick={() => {
                      setIsPlaying(true)
                      setCurrentVideo({
                        source: "https://player.vimeo.com/video/718985745?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                        getStartedText: "Watch Masterclass now",
                        getStartedURL: "/learn"
                      })
                    }}
                  >
                    <img src={playWhite} className="icon" alt="play icon" />
                  </button>
                </div>
                <div className="content">
                  <div className="wrapper">
                    <button
                      className="watch-button"
                      onClick={() => {
                        setIsPlaying(true)
                        setCurrentVideo({
                          source: "https://player.vimeo.com/video/718985745?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                          getStartedText: "Watch Masterclass now",
                          getStartedURL: "/learn"
                        })
                      }}
                    >
                      <img src={play} />
                      <div>Watch Trailer</div>
                    </button>
                    <h3>Legal Operations in Practice</h3>
                    <p>
                      Hear from our experts and clients as they share their experiences from the battlefield about innovation initiatives, the burgeoning role of legal project managers, and why legal operations is here to stay.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="masterclass-teaser">
                <div className="thumbnail">
                  <img src={teaser5} />
                  <button
                    className="play-button center"
                    onClick={() => {
                      setIsPlaying(true)
                      setCurrentVideo({
                        source: "https://player.vimeo.com/video/884428699?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                        getStartedText: "Watch Masterclass now",
                        getStartedURL: "/learn"
                      })
                    }}
                  >
                    <img src={playWhite} className="icon" alt="play icon" />
                  </button>
                </div>
                <div className="content">
                  <div className="wrapper">
                    <button
                      className="watch-button"
                      onClick={() => {
                        setIsPlaying(true)
                        setCurrentVideo({
                          source: "https://player.vimeo.com/video/884428699?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                          getStartedText: "Watch Masterclass now",
                          getStartedURL: "/learn"
                        })
                      }}
                    >
                      <img src={play} />
                      <div>Watch Trailer</div>
                    </button>
                    <h3 style={{ margin: "16px 0" }}>LegalOps - from Theory to Lift-Off</h3>
                    <p>
                      LegalOps titan Mary O'Carroll guides us through the transformative changes happening within the legal ecosystem. With a remarkable background that includes leadership roles at Google and the founding of the Corporate Legal Operations Consortium (CLOC), her insights span the evolution of legal operations and the pivotal role of technology in shaping the future of legal services.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div style={{ marginTop: 56 }}>
          <div className="mt-32 masterclass-indicators" id="masterclass-pagination" />
          <div style={{ marginTop: 32, display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 15, textAlign: 'right', flex: 1 }}>
              <div style={{ fontWeight: 500 }}>Speakers</div>
            </div>
            <div style={{ marginLeft: 15, textAlign: 'left', flex: 1 }}>
              <Link className="btn" style={{ border: '1px solid #D2D6DB' }} to="/learn">Learn more</Link>
            </div>
          </div>
        </div>
        <Swiper
          loop
          allowTouchMove={false}
          onSwiper={setSecondSwiper}
          controller={{ control: firstSwiper }}
          navigation={
            {
              nextEl: '#swiper-next',
              prevEl: '#swiper-prev',
            }
          }
        >
          <SwiperSlide>
            <div className="speakers-section">
              <div
                style={{
                  backgroundImage: `url(${lars})`,
                  backgroundSize: 'cover',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Lars Rasmussen's Masterclass now`,
                    getStartedURL: '/learn/lawyers-and-technologists'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>Lars Rasmussen</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Founder of Google Maps
                  <br />
                  Former Director of Engineering for Facebook
                  <br />
                  <br />
                  Lawyers and Technologists
                  <br />
                  the twain shall meet
                  <br />
                  <br />
                  11m 17s
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${david})`,
                  backgroundSize: 'cover',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch David Kerr's Masterclass now`,
                    getStartedURL: '/learn/judgment-day'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>David Kerr</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Former CEO of Bird & Bird
                  <br />
                  <br />
                  <br />
                  Judgment Day
                  <br />
                  the rise of the tech-enabled law firm
                  <br />
                  <br />
                  8m 14s
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${jason})`,
                  backgroundSize: 'cover',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Jason Barnwell's Masterclass now`,
                    getStartedURL: '/learn/legal-innovation'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>Jason Barnwell</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Microsoft’s Corporate Attorney
                  <br />
                  for External and Legal Affairs
                  <br />
                  <br />
                  Legal Innovation
                  <br />
                  We can do more
                  <br />
                  <br />
                  6m 30s
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="speakers-section -cols-5">
              <div
                style={{
                  backgroundImage: `url(${stephaniechris})`,
                  backgroundSize: 'cover',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/718640127?h=2bbd968b86&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Stéphanie Hamon and Chris Grant's Masterclass now`,
                    getStartedURL: '/learn/legal-ops-whats-the-fuss'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0, fontSize: 20 }}>Stéphanie Hamon</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Head of Legal Operations Consulting, Norton Rose Fulbright
                  <br />
                  <br />
                </div>
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>Chris Grant</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Lawtech / LegalOps Consultant
                  <br />
                  for startups & law firms
                </div>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  <br />
                  <br />
                  LegalOps
                  <br />
                  what's the fuss?
                  <br />
                  <br />
                  20m 20s
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${paul})`,
                  backgroundSize: 'cover',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/718649920?h=34d76be563&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Paul Hogg's Masterclass now`,
                    getStartedURL: '/learn/panel-firms'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>Paul Hogg</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Relationship Manager,
                  <br />
                  Barclays
                  <br />
                  <br />
                  Panel firms
                  <br />
                  It's all about the relationships!
                  <br />
                  <br />
                  9m 27s
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${catherine})`,
                  backgroundSize: 'cover',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/718639557?h=e9aa74916e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Catherine Goodman's Masterclass now`,
                    getStartedURL: '/learn/paving-the-way-for-legaltech-adoption'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>Catherine Goodman</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Practice Innovation & Knowledge Counsel,
                  <br />
                  Paul Hastings
                  <br />
                  <br />
                  Paving the way for
                  <br />
                  #legaltech adoption
                  <br />
                  <br />
                  5m 37s
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${helga})`,
                  backgroundSize: 'cover',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/718643443?h=09a7dc6124&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Helga Butcher's Masterclass now`,
                    getStartedURL: '/learn/legal-project-management'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>Helga Butcher</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Head of Legal Project Management,
                  <br />
                  Ashurst
                  <br />
                  <br />
                  Legal Project Management
                  <br />
                  it's here to stay
                  <br />
                  <br />
                  8m 13s
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${stephanie})`,
                  backgroundSize: 'cover',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/718644038?h=2a87edb5ea&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Stéphanie Hamon's Masterclass now`,
                    getStartedURL: '/learn/legal-ops-a-framework-for-success'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0 }}>Stéphanie Hamon</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Head of Legal Operations Consulting,
                  <br />
                  Norton Rose Fulbright
                  <br />
                  <br />
                  LegalOps
                  <br />
                  a framework for success
                  <br />
                  <br />
                  12m 37s
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="speakers-section -cols-5">
              <div
                style={{
                  backgroundImage: `url(${chris})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/873261246?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Chris Grant's Masterclass now`,
                    getStartedURL: '/learn/digital-transformation'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0, fontSize: 20 }}>Chris Grant</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Investor & Advisor
                </div>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  <br />
                  <br />
                  Digital Transformation and the
                  <br />
                  Modernisation of Legal Operations
                  <br />
                  <br />
                  10m 53s
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${ralph})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Ralph Baxter's Masterclass now`,
                    getStartedURL: '/learn/harnessing-the-power-of-technology'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0, fontSize: 20 }}>Ralph Baxter</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Former Chairman and CEO, of Orrick
                </div>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  <br />
                  <br />
                  Harnessing the power of technology to
                  <br />
                  become better lawyers
                  <br />
                  <br />
                  23m 33s
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `url(${mary})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 8,
                  flex: 1,
                  height: 348,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  padding: '0 12px 24px 12px',
                  textAlign: 'center',
                  lineHeight: 1.2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setIsPlaying(true)
                  setCurrentVideo({
                    source: 'https://player.vimeo.com/video/884428699?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                    getStartedText: `Watch Mary's Masterclass now`,
                    getStartedURL: '/learn/legalops-from-theory-to-lift-off'
                  })
                }}
              >
                <h5 style={{ color: 'white', fontWeight: 'bold', marginBottom: 0, fontSize: 20 }}>Mary O'Carroll</h5>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Chief Community Officer at Ironclad,
                </div>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  Former Google Director Legal Ops
                </div>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  and CLOC President
                </div>
                <div style={{ color: 'white', fontSize: '10px' }}>
                  <br />
                  <br />
                  LegalOps
                  <br />
                  from Theory to Lift-Off
                  <br />
                  <br />
                  15m 11s
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <button className="masterclass-swiper-btn -prev" id="swiper-prev">
          <ArrowLeftIcon fill="black" />
        </button>
        <button className="masterclass-swiper-btn -next" id="swiper-next">
          <ArrowRightIcon fill="black" />
        </button>
      </div>
    </div>
  )
}

export default Masterclass2;
