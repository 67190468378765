import React, { useEffect, useState, useRef } from 'react';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.min.css';

import law from '../../assets/images/logos/law.com.png';
import law360 from '../../assets/images/logos/law360.png';
import reuters from '../../assets/images/logos/reuters.png';

import arrow from '../../assets/images/icons/arrow-down.svg';
import upRight from '../../assets/images/icons/up-right.svg';


const PartnersCarousel = () => {
  const swiperRef = useRef(null)

  return (
    <div className="container-fluid px-0 mx-0 bg-white">
      <div className="partners-carousel fit-width">
        <div className="slider">
          <Swiper
            ref={swiperRef}
            slidesPerView={2.7}
            spaceBetween={32}
          >
            <a href="https://www.reuters.com/article/legaltech-lawadvisor/lawadvisor-partners-with-barclays-law-firms-on-new-legal-ops-tools-idUSL1N2K10QN" target="_blank" rel="noreferrer" className="_card" id={`_card-${1}`}>
              <div className="_body">
                <span className="single-quote">“</span>
                <img className="up-right" src={upRight} />
                <p className="content">
                  (LawAdvisor’s software) supports digital workflows for all aspects of operations from RFP and instruction to legal project management and financial tracking
                </p>
              </div>
              <img src={reuters} alt="logo" className="logo" />
            </a>
            <a href="https://www.law360.com/banking/articles/1348564" target="_blank" rel="noreferrer" className="_card" id={`_card-${2}`}>
              <div className="_body">
                <span className="single-quote">“</span>
                <img className="up-right" src={upRight} />
                <p className="content">
                  As the growing intersection between law and technology continues to take hold in the legal industry, LawAdvisor has launched new products geared toward corporate legal departments and law firms.
                </p>
              </div>
              <img src={law360} alt="logo" className="logo" />
            </a>
            <a href="https://www.law.com/international-edition/2021/01/27/barclays-adds-panel-law-firms-to-new-collaborative-platform/?slreturn=20210307065517" target="_blank" rel="noreferrer" className="_card" id={`_card-${2}`}>
              <div className="_body">
                <span className="single-quote">“</span>
                <img className="up-right" src={upRight} />
                <p className="content">
                  Barclays Adds Panel Law Firms to New Collaborative Platform. The new tech solution is one of two the bank created with LawAdvisor and firms including Allen & Overy and Paul Hastings.
                </p>
              </div>
              <img src={law} alt="logo" className="logo" />
            </a>
          </Swiper>
        </div>
        <div className="nav-buttons">
          <span
            className="prev"
            id="swiper-button-prev"
            onClick={() => {
              swiperRef.current.swiper.slidePrev()
            }}
          >
            <img src={arrow} alt="" />
          </span>
          <span
            className="next"
            id="swiper-button-next"
            onClick={() => {
              swiperRef.current.swiper.slideNext()
            }}
          >
            <img src={arrow} alt="" />
          </span>
        </div>
      </div>
    </div>
  )
}

export default PartnersCarousel;
