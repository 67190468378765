import React from 'react';

import law from '../../assets/images/logos/law.com.png';
import law360 from '../../assets/images/logos/law360.png';
import reuters from '../../assets/images/logos/reuters.png';
import upRight from '../../assets/images/icons/up-right.svg';

const Card = ({ content, logo, param, url }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="_card col-12 mb-2" id={`_card-${param}`}>
      <div className="_body d-flex flex-column">
        <span className="single-quote">“</span>
        <img className="up-right" src={upRight} />
        <p className="content">{content}</p>
      </div>
      <img src={logo} alt="logo" className="logo" />
    </a>
  )
}

function PartnersCards() {
  return (
    <div className="container-fluid px-0 mx-0 bg-white">
      <div className="partners-carousel container-fluid fit-width">
        <div className="row px-3">
          <Card
            param={1}
            key={1}
            logo={reuters}
            url="https://www.reuters.com/article/legaltech-lawadvisor/lawadvisor-partners-with-barclays-law-firms-on-new-legal-ops-tools-idUSL1N2K10QN"
            content="(LawAdvisor’s software) supports digital workflows for all aspects of operations from RFP and instruction to legal project management and financial tracking"
          />
          <Card
            param={2}
            key={2}
            logo={law360}
            url="https://www.law360.com/banking/articles/1348564"
            content="As the growing intersection between law and technology continues to take hold in the legal industry, LawAdvisor has launched new products geared toward corporate legal departments and law firms."
          />
          <Card
            param={3}
            key={3}
            logo={law}
            url="https://www.law.com/international-edition/2021/01/27/barclays-adds-panel-law-firms-to-new-collaborative-platform/?slreturn=20210307065517"
            content="Barclays Adds Panel Law Firms to New Collaborative Platform. The new tech solution is one of two the bank created with LawAdvisor and firms including Allen & Overy and Paul Hastings."
          />
        </div>
      </div>
    </div>
  )
}

export default PartnersCards;
