import React, { useEffect, useState } from 'react';
import InputGroup from './InputGroup';
import VideoPlayer from '../common/VideoPlayer';

import play from "../../assets/images/icons/play-white.svg";
import preview from "../../assets/videos/home-video-preview.mp4";
import full from "../../assets/videos/home-video-full.mp4";
import poster from "../../assets/images/lawadvisor-homepage-01.jpg";
import InputGroupMobile from './InputGroupMobile';

function VideoSectionMobile() {
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);

  useEffect(() => {
    const video = document.getElementById('video-preview-mobile');
    setTimeout(() => {
      if (video) {
        video.play();
        video.setAttribute('autoplay', true);
      }
    }, 3000);
  }, [])

  useEffect(() => {
    const video = document.getElementById('video-preview-mobile');
    if (videoPlayerOpen) {
      video && video.pause();
    }
  }, [videoPlayerOpen])

  const handleVideos = () => {
    setVideoPlayerOpen(false);
    const video = document.getElementById('video-preview-mobile');
    if (video) {
      video.play();
      video.setAttribute('autoplay', true);
    }
  }

  return (
    <div className="container-fluid px-0 mx-0 video-section-mobile">
      <VideoPlayer
        open={videoPlayerOpen}
        onClose={() => handleVideos()}
        video={full}
        autoplay
      />
      <h1 className="title">Lawyer like <br />never before</h1>
      <h5 className="description">Giving you the power to transform legal service delivery.</h5>
      <div className="video-container">
        <div
          className="video _video"
          role="button"
          tabIndex="-1"
          onClick={() => {
            setVideoPlayerOpen(true);
          }}
        >
          <div className="play-button center">
            <img src={play} className="icon" alt="play icon" />
          </div>
          <video id="video-preview-mobile" playsInline muted loop poster={poster} className="_preview">
            <source src={preview} type="video/mp4" />
          </video>
          {/* <img className="mobile-poster" src={poster}/> */}
        </div>
      </div>
      <InputGroupMobile />

    </div>
  )
}

export default VideoSectionMobile;
