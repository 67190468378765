import React from 'react';
import { Link } from 'gatsby';

import addleshawGoddard from '../../assets/images/logos/company_logo/addleshaw-goddard--dark.png';
import allenAndOvery from '../../assets/images/logos/company_logo/allen-and-overy.png';
import ashurst from '../../assets/images/logos/company_logo/ashurst.png';
import paulHastings from '../../assets/images/logos/company_logo/paul-hastings.png';
import pinsentMasons from '../../assets/images/logos/company_logo/pinsent-masons.png';
import barclaysEagleLabs from '../../assets/images/logos/company_logo/barclays-eagle-labs-02.png';
import birdandbird from '../../assets/images/logos/company_logo/birdandbird.png';
import simmons from '../../assets/images/logos/company_logo/simmons-and-simmons.png';
import lonelyPlanet from '../../assets/images/logos/company_logo/lonely-planet.png';
import pexa from '../../assets/images/logos/company_logo/pexa-black.svg';
import kroll from '../../assets/images/logos/company_logo/kroll.png';
import tlt from '../../assets/images/logos/company_logo/tlt.svg';

const PartnerCard = ({ icon }) => (
  <div className="partner-card">
    <img className="logo" src={icon} alt="partner-logo" />
  </div>
)

function Partners() {
  return (
    <div className="container-fluid px-0 mx-0 bg-white">
      <div className="partners-section fit-width">
        <div className="row">
          <div className="col-12">
            <h2>Pioneering innovation and technology <br /> alongside the world's best </h2>
            <span className="description">
              LawAdvisor’s low-code and customisable software can help you build powerful business <br />
            applications that radically transform your service offering and provide you with a competitive <br />
            edge. The world's most pioneering legal departments use LawAdvisor to transform the <br />
            delivery of legal services.
          </span>
          </div>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={simmons} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={addleshawGoddard} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={allenAndOvery} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={ashurst} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={birdandbird} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={paulHastings} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={pinsentMasons} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={barclaysEagleLabs} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={lonelyPlanet} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={pexa} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={kroll} />
          </Link>
          <Link to="/steering-group" className="col-6 col-md-4 col-lg-3">
            <PartnerCard icon={tlt} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Partners;
