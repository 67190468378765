import React, { useState } from 'react';
import davidkerr from '../../assets/images/david-kerr.jpg';
import birdandbird from '../../assets/images/logos/company_logo/birdandbird.png';
import JobPost from '../../pages/work-with-us';
import ModalDialog from '../common/ModalDialog';


function QuoteSection() {
  const [workWithUsDialog, setWorkWithUsDialog] = useState(false);
  return (
    <div className="container-fluid pb-5 px-0 mx-o bg-white">
      <div className="quote-section fit-width">
        <div className="quote-container">
          <img src={davidkerr} alt="" className="thumbnail" />
          <div className="quotes">
            <span className="single-quote">“</span>
            <div className="multi-quote">
              <p className="quote">We believe the best law firm in the world is the one that is most tech-enabled. LawAdvisor’s technology allows us to deliver and exceed the expectations of our clients, and arms our lawyers with the right tools to practice in this digital world.</p>
              <p className="quote name"><b>David Kerr</b></p>
              <p className="quote position">Bird&Bird, CEO</p>
              <img src={birdandbird} alt="" className="logo" />
            </div>
          </div>
        </div>
        <div className="work-together">
          <h2 className="text-primary">You’ve got big dreams.</h2>
          <h2 className="text-primary">We’ll help you achieve them</h2>
          <button onClick={() => setWorkWithUsDialog(true)} className="btn btn-outline-primary">Let's work together</button>
        </div>
        {workWithUsDialog && <ModalDialog closeAction={() => setWorkWithUsDialog(false)} scroll={true}>
          <div className="container px-0">
            <JobPost />
          </div>
        </ModalDialog>}
      </div>
    </div>
  )
}

export default QuoteSection;
