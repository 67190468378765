import React from 'react'

function ProductCard({ title, logo, bgColor, desc1, desc2, children, url }) {
  return (
    <div onClick={() => window.open(url)} className="product-item" style={{ backgroundColor: bgColor }}>
      <img src={logo} alt="" className="logo" />
      <h3 className="title">{title}</h3>
      <div className="description">
        <p>{desc1}</p>
        <p>{desc2}</p>
      </div>
      {children}
    </div>
  )
}

export default ProductCard;
