import React, { useEffect, useState } from 'react';
import InputGroup from './InputGroup';
import VideoPlayer from '../common/VideoPlayer';

import play from "../../assets/images/icons/play-white.svg";
import preview from "../../assets/videos/home-video-preview.mp4";
import full from "../../assets/videos/home-video-full.mp4";
import poster from "../../assets/images/lawadvisor-homepage-01.jpg";

function VideoSection() {
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);

  useEffect(() => {
    const video = document.getElementById('video-preview');
    setTimeout(() => {
      if (video) {
        video.play();
        video.setAttribute('autoplay', true);
      }
    }, 3000);
  }, [])

  useEffect(() => {
    const video = document.getElementById('video-preview');
    if(videoPlayerOpen) {
      video && video.pause();
    }
  }, [videoPlayerOpen])

  const handleVideos = () => {
    setVideoPlayerOpen(false);
    const video = document.getElementById('video-preview');
    if (video) {
      video.play();
      video.setAttribute('autoplay', true);
    }
  }

  return (
    <div className="container-fluid px-0 mx-0 _h-100 bg-white">
      <VideoPlayer
        open={videoPlayerOpen}
        onClose={() => handleVideos()}
        video={full}
        autoplay
      />
      <div className="video-section-home">
        <video id="video-preview" playsInline muted loop poster={poster} className="bg-video">
          <source src={preview} type="video/mp4" />
        </video>
        <div className="input-group fit-width">
          <div className="form-group">
            <InputGroup />
          </div>
          <div className="play-button" onClick={() => setVideoPlayerOpen(true)}>
            <img src={play} className="icon" alt="play icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoSection;
