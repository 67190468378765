import React, { useState } from 'react';

import play from "../../assets/images/icons/play.svg";

import fibonacci from "../../assets/images/logos/ventures_logo/Fibonacci - Logoweb.svg";
import legaleye from "../../assets/images/logos/ventures_logo/legaleye-logo-dark.svg";
import rapidcontract from "../../assets/images/logos/ventures_logo/rapid contracts.svg";
import lawadvisor from '../../assets/images/logos/la_ventures_logo/LawAdvisor Logo - Full (colored) dark.svg';

import FibonacciProduct from "../../assets/images/ventures_thumbnails/thumbnail-fibonacci.png";
import LegalEyeProduct from "../../assets/images/ventures_thumbnails/thumbnail-legaleye.png";
import RapidContractProduct from "../../assets/images/ventures_thumbnails/thumbnail-rapidcontracts.png";
import LawAdvisorProduct from "../../assets/images/ventures_thumbnails/thumbnail-core.png";

import VideoPlayer from '../common/VideoPlayer';
import ProductCard from './ProductCard';

import video from '../../assets/videos/fibonacci-home.mp4';

function OurVentures() {
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);

  return (
    <div className="container-fluid px-0 mx-0 bg-white">
      <div className="fit-width ventures-section">
        <VideoPlayer
          open={videoPlayerOpen}
          onClose={() => setVideoPlayerOpen(false)}
          video={video}
          autoplay
        />
        <div className="row">
          <div className="col-12 section-title">
            <h3 className="text-muted">Our</h3>
            <h2>Ventures</h2>
          </div>
          <div className="col-6">
            <ProductCard
              title="Work beautifully."
              desc1="It’s time that matter management tools are more than just glorified invoicing services, and for ‘legal technology’ to enable us to do more than the simple filing and storage of documents."
              desc2="Fibonacci is the first built-for-purpose legal project management system that provides legal departments with the latest tools to easily communicate and collaborate during the complete lifecycle of a matter, without the email and spreadsheet chaos."
              logo={fibonacci}
              bgColor="#F6F5FF"
              url="https://fibonacci.legal"
            >
              <div className="video-container">
                <div
                  className="video"
                  role="button"
                  tabIndex="-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setVideoPlayerOpen(true);
                  }}
                >
                  <div
                    className="play"
                  >
                    <img src={play} className="icon" alt="play icon" />
                  </div>
                  <img src={FibonacciProduct} className="pic" alt="play icon" />
                </div>
              </div>
            </ProductCard>
          </div>
          <div className="col-6">
            <ProductCard
              title="Powering smarter relationships"
              desc1="‘Legal’ is comprised of a series of interrelated and complex relationships that its stakeholders need to understand in order to drive value."
              desc2="LegalEye allows in-house counsel and law firms to better track their interactions, understand their key engagements, and boosts visibility around dealflow in order to allow both parties to drive more meaningful and value-driven relationships."
              logo={legaleye}
              bgColor="#FFF8FE"
              url="https://legaleye.com"
            >
              <img src={LegalEyeProduct} alt="" className="product" />
            </ProductCard>
          </div>
          <div className="col-6">
            <ProductCard
              title="Smarter and faster contracting."
              desc1="Create, negotiate, and execute your contracts all in the one platform without the long email chains, attachments, and tracked changes. Leverage templates and workflows for repeat transactions, ensure compliance, and secure your deals with LawAdvisor Vault™."
              desc2=""
              logo={rapidcontract}
              bgColor="#FAF9F8"
              url="https://rapidcontracts.lawadvisor.com/"
            >
              <img src={RapidContractProduct} alt="" className="product -rapid-contracts" />
            </ProductCard>
          </div>
          <div className="col-6">
            <ProductCard
              title="Legal help for everybody."
              desc1="Legal help is trapped in the minds and experiences of lawyers, or buried in books and legislation that only lawyers can understand. LawAdvisor is changing this by connecting everyday people and businesses with expert and easy to understand legal advice and support."
              desc2=""
              logo={lawadvisor}
              bgColor="#F6F8F8"
              url="https://lawadvisor.com/au/users/sign_in"
            >
              <img src={LawAdvisorProduct} alt="" className="product" />
            </ProductCard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurVentures;

