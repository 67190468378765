import React, { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { submitForm } from '../../utils/api';
import Alert from '../common/AlertV2';

function InputGroup() {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [value, setValue] = useState('');
  const [submitted, setSubmitted] = useState(null);
  const [showAttribution, setShowAttribution] = useState(false);
  const [alertOptions, setAlertOptions] = useState(null)

  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    <div className="input-group-home">
      <h1 className="title">Lawyer like <br />never before</h1>
      <span className="description">Giving you the power to transform legal <br/> service delivery.</span>
      <button
        className="btn btn-primary"
        onClick={() => {
          document.getElementById('work-with-us-button').click()
        }}
      >
      Work with us
      </button>
    </div>
  )
}

export default InputGroup;
