import React, { useState, useRef, useEffect } from 'react'

import { Link } from 'gatsby'
import VimeoPlayer from '@vimeo/player'

import SwiperCore, { Controller, Pagination } from 'swiper6'
import { Swiper, SwiperSlide } from 'swiper6/react';
import 'swiper6/swiper-bundle.min.css';
import 'swiper6/swiper.min.css';

import play from "../../assets/images/icons/play.svg";
import logo from "../../assets/images/logos/masterclass-by-la-ventures.png"
import teaser1 from "../../assets/images/masterclass/teaser-mobile-1.png"
import teaser2 from "../../assets/images/masterclass/teaser-mobile-2.png"
import teaser5 from "../../assets/images/masterclass/teaser-mobile-5.png"

import lars from "../../assets/images/masterclass/lars-rasmussen.png"
import david from "../../assets/images/masterclass/david-kerr.png"
import jason from "../../assets/images/masterclass/jason-barnwell.png"
import paul from '../../assets/images/masterclass/paul-hogg.png'
import stephanieAndChris from '../../assets/images/masterclass/stephanie-hamon-chris-grant.png'
import catherine from '../../assets/images/masterclass/catherine-goodman.png'
import chris from '../../assets/images/masterclass/chris-grant-2.png'
import ralph from "../../assets/images/masterclass/ralph-baxter-2.png"
import mary from "../../assets/images/masterclass/mary-ocarroll.png"

import CloseIcon from '../../assets/images/icons/close-white.svg';

SwiperCore.use([Controller, Pagination])

const MobilePlayer = ({ currentVideo: {source}, onClose }) => {
  const player = useRef(null)
  const [playing, setPlaying] = useState(false)
  const playerController = useRef(null)

  useEffect(() => {
    if (player != null) {
      playerController.current = new VimeoPlayer(player.current)
      playerController.current.on('pause', () => {
        setPlaying(false)
      })
      playerController.current.on('play', () => {
        setPlaying(true)
      })
    }

    document.getElementsByTagName('html')[0].style.setProperty('overflow-y', 'hidden', 'important')

    return () => {
      document.getElementsByTagName('html')[0].style.setProperty('overflow-y', 'auto', 'important')
    }
  }, [])

  return (
    <div className="mobile-teaser-player">
      <button className="position-absolute close-button" onClick={() => onClose()}>
        <img src={CloseIcon} />
      </button>
      <div className="position-relative full-width">
        <div
          style={{ padding: '42.19% 0 0 0', position: 'relative' }}
        >
          <iframe
            ref={player}
            src={source}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title="Teaser 1 - Visionaries, Innovators, and Dreamers">
          </iframe>
        </div>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  )
}

const MasterclassMobile = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)

  const [activeSlide, setActiveSlide] = useState(0)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  const teasers = [
    "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
    "https://player.vimeo.com/video/718985745?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
    "https://player.vimeo.com/video/884428699?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
  ]

  return (
    <div className="masterclass-mobile container-fluid bg-white" style={{ paddingTop: 32 }}>
      <div style={{ textAlign: 'center' }}>
        <img src={logo} style={{ height: 64 }} />
      </div>
      <div style={{ maxWidth: '90vw', margin: '0 auto' }}>
        <Swiper
          loop
          onSwiper={setFirstSwiper}
          controller={{ control: secondSwiper }}
          onSlideChange={(s) => {
            setActiveSlide(s.realIndex)
            setIsPlaying(false)
          }}
          pagination={{ clickable: true, el: '#masterclass-swiper-pagination' }}
          initialSlide={2}
        >
          <SwiperSlide>
            <div style={{ marginTop: 48 }}>
              <img src={teaser1} className="teaser-thumbnail -mobile" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ marginTop: 48 }}>
              <img src={teaser2} className="teaser-thumbnail -mobile" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ marginTop: 48 }}>
              <img src={teaser5} className="teaser-thumbnail -mobile" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <button
        className="watch-button"
        style={{ margin: 'auto', marginTop: 48 }}
        onClick={() => {
          setIsPlaying(true)
          setCurrentVideo({
            source: teasers[activeSlide]
          })
        }}
      >
        <img src={play} />
        <div>Watch Trailer</div>
      </button>
      <div className="mt-32 masterclass-indicators" style={{ marginTop: 32 }} id="masterclass-swiper-pagination" />
      <div style={{ maxWidth: '90vw', margin: '0 auto' }}>
        <Swiper
            loop
            onSwiper={setSecondSwiper}
            controller={{ control: firstSwiper }}
          >
            <SwiperSlide>
              <h1 style={{ marginTop: 32, marginBottom: 0 }}>
                The Industry Titans
              </h1>
              <p style={{ color: 'black', margin: 0, marginTop: 24 }}>
                Join our Visionaries, our Innovators, and our Dreamers as they share their insights on legal innovation.
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <h1 style={{ marginTop: 32, marginBottom: 0 }}>
                Legal Operations in Practice
              </h1>
              <p style={{ color: 'black', margin: 0, marginTop: 24 }}>
                Hear from our experts and clients as they share their experiences from the battlefield about innovation initiatives,
                the burgeoning role of legal project managers, and why legal operations is here to stay.
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <h1 style={{ marginTop: 32, marginBottom: 0 }}>
                Harnessing the power of technology to become better lawyers
              </h1>
              <p style={{ color: 'black', margin: 0, marginTop: 24 }}>
                LegalOps titan Mary O'Carroll guides us through the transformative changes happening within the legal ecosystem. With a remarkable background that includes leadership roles at Google and the founding of the Corporate Legal Operations Consortium (CLOC), her insights span the evolution of legal operations and the pivotal role of technology in shaping the future of legal services.
              </p>
            </SwiperSlide>
        </Swiper>
      </div>
      <div style={{ marginTop: 32, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ marginRight: 15, flex: 1, textAlign: 'left' }}>
          <div style={{ fontWeight: 500 }}>Speakers</div>
        </div>
        <div style={{ marginLeft: 15, flex: 1, textAlign: 'right' }}>
          <Link className="btn" style={{ border: '1px solid #D2D6DB' }} to="/learn">Learn more</Link>
        </div>
      </div>
      <div className="speakers">
        <div
          className={`members ${activeSlide !== 0 ? '-inactive' : ''}`}
        >
          <div
            style={{ flex: 1, cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={lars} />
          </div>
          <div
            style={{ flex: 1, cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={david} />
          </div>
          <div
            style={{ flex: 1, cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={jason} />
          </div>
        </div>
        <div
          className={`members ${activeSlide !== 1 ? '-inactive' : ''}`}
          style={{ position: 'absolute', top: 0 }}
        >
          <div
            style={{ flex: 1, cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/718640127?h=2bbd968b86&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={stephanieAndChris} />
          </div>
          <div
            style={{ flex: 1, cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/718649920?h=34d76be563&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={paul} />
          </div>
          <div
            style={{ flex: 1, cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/718639557?h=e9aa74916e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={catherine} />
          </div>
        </div>
        <div
          className={`members ${activeSlide !== 2 ? '-inactive' : ''}`}
          style={{ position: 'absolute', top: 0 }}
        >
          <div
            style={{ flex: 1, cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/873261246?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={chris} />
          </div>
          <div
            style={{ flex: 1, cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={ralph} />
          </div>
          <div
            style={{ flex: 1, cursor: 'pointer' }}
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/884428699?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={mary} />
          </div>
        </div>
      </div>
      {isPlaying &&
        (<MobilePlayer
          onClose={() => setIsPlaying(false)}
          currentVideo={currentVideo}
        />)
      }
    </div>
  )
}

export default MasterclassMobile;

