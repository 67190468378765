import React from 'react';

import LawSection from '../components/home/LawSection';
import Masterclass from '../components/home/Masterclass';
import MasterclassMobile from '../components/home/MasterclassMobile';
import OurVentures from '../components/home/OurVentures';
import OurVenturesMobile from '../components/home/OurVenturesMobile';
import Partners from '../components/home/Partners';
import PartnersCards from '../components/home/PartnersCards';
import PartnersCarousel from '../components/home/PartnersCarousel';
import QuoteSection from '../components/home/QuoteSection';
import AccreditationSection from '../components/home/AccreditationSection';
import VideoSection from '../components/home/VideoSection';
import VideoSectionMobile from '../components/home/VideoSectionMobile';
import Layout from '../components/layout/Layout';
import LatestNews from '../components/news/LatestNews';

function Home() {
  return (
    <Layout
      title="Lawyer like never before"
      description="because status quo no longer works"
      image="/metaimages/meta-image-la-ventures.png"
      revertColor
    >
      <div className="d-block d-lg-none">
        <VideoSectionMobile />
      </div>
      <div className="d-none d-lg-block">
        <VideoSection />
      </div>
      <Partners />
      <div className="d-block d-lg-none">
        <PartnersCards />
      </div>
      <div className="d-none d-lg-block">
        <PartnersCarousel />
      </div>
      <LawSection />
      <div className="d-block d-lg-none">
        <MasterclassMobile />
      </div>
      <div className="d-none d-lg-block">
        <Masterclass />
      </div>
      <div className="d-block d-lg-none">
        <OurVenturesMobile />
      </div>
      <div className="d-none d-lg-block">
        <OurVentures />
      </div>
      <AccreditationSection />
      <QuoteSection />
      <LatestNews />
    </Layout>
  );
}

export default Home;
